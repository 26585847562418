import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// import axios from 'axios'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'

export default function useKhachHangList() {
  // Use toast
  const toast = useToast()
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'STT', thStyle: { width: '1%' } },
    { key: 'hoten', sortable: true, label: 'Họ tên' },
    { key: 'ngay_sinh', sortable: true, label: 'Ngày sinh' },
    { key: 'dien_thoai', sortable: true, label: 'Điện thoại' },
    { key: 'khu_vuc', sortable: true, label: 'Tỉnh thành' },
    { key: 'dai_ly', sortable: true, label: 'Đại lý' },
    { key: 'actions', label: 'Tác vụ', thStyle: { width: '10%' } },
  ]
  const perPage = ref(20)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const fieldsTimKiem = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const currentUser = getUserData()

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })
  const fetchUsers = (ctx, callback) => {
    const userData = getUserData()
    store.state.showBlock = true
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        offset: currentPage.value,
        limit: perPage.value,
        type: 'Khách hàng',
        auth: userData.auth_key,
        uid: userData.id,
        fieldsSearch: fieldsTimKiem,
      })
      .then(response => {
        const { users, rows, taiFileMessage } = response.data

        callback(users)
        totalUsers.value = rows
        store.state.showBlock = false

        if (taiFileMessage !== '') {
          window.open(taiFileMessage, '_blank')
        }
      })
      .catch(e => {
        store.state.showBlock = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            variant: 'danger',
            text: e,
          },
        })
      })
  }

  const xoaKhachHang = (id, callback, before, after, showToast) => {
    before()
    store
      .dispatch('app-user/deleteKhachHang', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        khach_hang: id,
      })
      .then(response => {
        callback()
        after()
        showToast(response.data.message, 'success')
      })
      .catch(e => {
        after()
        showToast(e.message, 'danger')
      })
  }

  return {
    fieldsTimKiem,
    fetchUsers,
    xoaKhachHang,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
