<template>
  <b-sidebar
    id="search-sidebar"
    :visible="isTimKiemSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-tim-kiem-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Tìm kiếm khách hàng
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Họ tên -->
          <validation-provider
            #default="validationContext"
            name="Họ tên"
          >
            <b-form-group
              label="Họ tên"
              label-for="search_ho-ten"
            >
              <b-form-input
                id="search_ho-ten"
                v-model="userData.hoten"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Họ và tên đầy đủ"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Điện thoại -->
          <validation-provider
            #default="validationContext"
            name="Điện thoại"
          >
            <b-form-group
              label="Điện thoại"
              label-for="search_phone_khach_hang"
            >
              <b-form-input
                id="search_phone_khach_hang"
                v-model="userData.dien_thoai"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
          >
            <b-form-group
              label="Email"
              label-for="search_email_khach_hang"
            >
              <b-form-input
                id="search_email_khach_hang"
                v-model="userData.email"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <!-- Ngày sinh -->
              <validation-provider
                #default="validationContext"
                name="Ngày sinh từ"
              >
                <b-form-group
                  label="Ngày sinh từ"
                  label-for="ngay-sinh-tu"
                >
                  <flat-pickr
                    id="ngay-sinh-tu"
                    v-model="userData.ngay_sinh_tu"
                    class="form-control"
                    :config="configDate"
                    placeholder="Chọn ngày"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <!-- Ngày sinh -->
              <validation-provider
                #default="validationContext"
                name="đến"
              >
                <b-form-group
                  label="đến"
                  label-for="ngay-sinh-den"
                >
                  <flat-pickr
                    id="ngay-sinh-den"
                    v-model="userData.ngay_sinh_den"
                    class="form-control"
                    :config="configDate"
                    placeholder="Chọn ngày"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Địa chỉ -->
          <validation-provider
            #default="validationContext"
            name="Địa chỉ"
          >
            <b-form-group
              label="Địa chỉ"
              label-for="search_dia-chi"
            >
              <b-form-textarea
                id="search_dia-chi"
                v-model="userData.dia_chi"
                multiple="true"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                rows="2"
                placeholder="Thông tin địa chỉ chi tiết"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <!-- Ngày nhập -->
              <validation-provider
                #default="validationContext"
                name="Ngày cập nhật từ"
              >
                <b-form-group
                  label="Ngày cập nhật từ"
                  label-for="ngay-cap-nhat-tu"
                >
                  <flat-pickr
                    id="ngay-cap-nhat-tu"
                    v-model="userData.ngay_nhap_tu"
                    class="form-control"
                    :config="configDate"
                    placeholder="Chọn ngày"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <!-- Ngày nhập -->
              <validation-provider
                #default="validationContext"
                name="đến"
              >
                <b-form-group
                  label="đến"
                  label-for="ngay-cap-nhat-den"
                >
                  <flat-pickr
                    id="ngay-cap-nhat-den"
                    v-model="userData.ngay_nhap_den"
                    class="form-control"
                    :config="configDate"
                    placeholder="Chọn ngày"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              <feather-icon
                icon="SearchIcon"
              />
              Tìm kiếm
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="timVaTaiFile()"
            >
              <feather-icon
                icon="DownloadCloudIcon"
                size="15"
              />
              Tải file
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Hủy
            </b-button>
          </div>

          <div class="mt-4" />
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import { Vi } from 'flatpickr/dist/l10n/vn'

export default {
  components: {
    flatPickr,
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTimKiemSidebarActive',
    event: 'update:is-tim-kiem-sidebar-active',
  },
  props: {
    isTimKiemSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      configDate: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Vi, // locale for this instance only
      },
    }
  },
  setup() {
    const blankUserData = {
      hoten: '',
      dien_thoai: '',
      ngay_sinh_tu: '',
      ngay_sinh_den: '',
      ngay_nhap_tu: '',
      ngay_nhap_den: '',
      email: '',
      dia_chi: '',
    }

    const listVaiTro = ref([])

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const setUserData = user => {
      userData.value = user
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      listVaiTro,
      userData,
      refFormObserver,
      getValidationState,
      resetForm,
      setUserData,
    }
  },
  methods: {
    submit(taiFile) {
      const currentUser = getUserData()

      Object.assign(this.userData, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        timKiem: 1,
        taiFile,
      })

      this.$root.$emit('timKiemKhachHang', this.userData)
    },
    onSubmit() {
      this.submit()
    },

    timVaTaiFile() {
      this.submit(true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
