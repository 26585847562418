<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>
    <khach-hang-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />
    <thanh-toan-them
      :is-thanh-toan-them-sidebar-active.sync="isThanhToanThemSidebarActive"
      @refetch-data="refetchData"
    />
    <tim-kiem
      :is-tim-kiem-sidebar-active.sync="isTimKiemSidebarActive"
      @refetch-data="refetchData"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title>Danh sách khách hàng</b-card-title>
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          dropright
          text="Chức năng"
          :right="$store.state.appConfig.isRTL"
        >
          <b-dropdown-item @click="isAddNewUserSidebarActive = true">
            <feather-icon
              icon="UserPlusIcon"
              class="mr-50"
            />
            <span class="align-middle ml-50">Thêm khách hàng</span>
          </b-dropdown-item>

          <b-dropdown-item @click="isTimKiemSidebarActive = true">
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle ml-50">Tìm kiếm và tải file</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        empty-text="Không có dữ liệu"
        show-empty
        responsive="sm"
        stacked="md"
        :items="fetchUsers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        primary-key="id"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- A virtual  so_tien_da_thanh_toan column -->
        <template #cell(so_tien_da_thanh_toan)="data">
          <div>
            <b-badge
              pill
              :variant="`light-${(data.item.so_tien_da_thanh_toan <= 0 && data.item.so_tien_can_thanh_toan > 0) ? 'danger' : ( Number.parseInt(data.item.so_tien_da_thanh_toan) >= data.item.so_tien_can_thanh_toan ? 'success' : 'warning')}`"
              class="text-capitalize"
            >
              {{ data.item.so_tien_da_thanh_toan != null ? Number( data.item.so_tien_da_thanh_toan).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}
            </b-badge>
          </div>
          <small class="text-muted">/
            {{ data.item.so_tien_can_thanh_toan != null ? Number( data.item.so_tien_can_thanh_toan).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}</small>
        </template>
        <!-- A virtual id column -->
        <template #cell(id)="data">
          <p class="text-right">
            {{ data.index+1 }}
          </p>
        </template>
        <!-- Column: email -->
        <template #cell(hoten)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'xem-bai', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap text-primary"
            >
              {{ data.item.hoten }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            dropright
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'xem-bai', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Xem bài</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="taiFile(data.item.id, 'Tổng quát', 1)"
            >
              <feather-icon icon="DownloadCloudIcon" />
              <span class="align-middle ml-50">Tổng quát</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="taiFile(data.item.id, 'Tổng quát', 2)"
            >
              <feather-icon icon="DownloadCloudIcon" />
              <span class="align-middle ml-50">Tổng quát V2</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="taiFile(data.item.id, 'Hướng nghiệp')"
            >
              <feather-icon icon="DownloadCloudIcon" />
              <span class="align-middle ml-50">Hướng nghiệp</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="taiFile(data.item.id, 'KID')"
            >
              <feather-icon icon="DownloadCloudIcon" />
              <span class="align-middle ml-50">Tải bài KID</span>
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="Number(data.item.so_tien_da_thanh_toan) > 0 "
              @click="suaKhach(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Sửa</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="Number(data.item.so_tien_da_thanh_toan) > 0"
              @click="xoaKhach(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Xóa</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Dòng {{ dataMeta.from }} đến {{ dataMeta.to }} / {{ dataMeta.of }} bản ghi</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BMedia, BLink, BPagination, BDropdown, BDropdownItem,
  BCardTitle, BBadge,
  BCardHeader,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ThanhToanThem from '@/views/users/khach-hang/ThanhToanThem.vue'
import { getUserData } from '@/auth/utils'
import TimKiem from '@/views/users/khach-hang/TimKiem.vue'
import KhachHangListAddNew from './KhachHangListAddNew.vue'
import useKhachHangList from './useKhachHangList'
import userStoreModule from '../userStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    TimKiem,
    KhachHangListAddNew,
    ThanhToanThem,
    BCardTitle,
    BCardHeader,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BLink,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const isThanhToanThemSidebarActive = ref(false)
    const isTimKiemSidebarActive = ref(false)

    const {
      fieldsTimKiem,
      thucHienTimKiem,
      fetchUsers,
      xoaKhachHang,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useKhachHangList()

    return {
      fieldsTimKiem,
      thucHienTimKiem,
      // Sidebar
      isAddNewUserSidebarActive,
      isThanhToanThemSidebarActive,
      isTimKiemSidebarActive,
      fetchUsers,
      xoaKhachHang,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  mounted() {
    this.$root.$on('timKiemKhachHang', data => {
      this.isTimKiemSidebarActive = false

      this.fieldsTimKiem = data
      this.thucHienTimKiem = true
      this.refetchData()
    })
  },
  methods: {
    xoaKhach(id) {
      this.$swal({
        title: 'Bạn có chắc chắn thực hiện việc này?',
        text: 'Dữ liệu sau khi xóa không thể phục hồi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.xoaKhachHang(id, () => {
            this.$refs.refUserListTable.refresh()
          }, () => {
            this.$store.state.showBlock = true
          }, () => {
            this.$store.state.showBlock = false
          }, (message, type) => {
            this.showToast(type, 'Thông báo', 'BellIcon', message)
          })
        }
      }).catch(() => {
      })
    },
    suaKhach(id) {
      this.isAddNewUserSidebarActive = true
      this.$root.$emit('updateKhach', id)
    },
    thanhToanThem(id) {
      this.isThanhToanThemSidebarActive = true
      this.$root.$emit('thanhToanThem', id)
    },
    taiFile(id, type, version) {
      const currentUser = getUserData()
      store.state.showBlock = true
      store.dispatch('app-user/taiFile', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        khach_hang: id,
        type,
        version,
      })
        .then(response => {
          this.$swal({
            title: response.data.title,
            icon: 'info',
            html: response.data.content,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: 'Đóng lại',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          store.state.showBlock = false
        })
        .catch(e => {
          this.showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          store.state.showBlock = false
        })
    },
    taiTileCacConSo(id) {
      const currentUser = getUserData()
      store.state.showBlock = true
      store.dispatch('app-user/taiFileTiLeCacConSo', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        khach_hang: id,
      })
        .then(response => {
          this.$swal({
            title: response.data.title,
            icon: 'info',
            html: response.data.content,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: 'Đóng lại',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          store.state.showBlock = false
        })
        .catch(() => {
          store.state.showBlock = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
