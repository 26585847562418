<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Thông tin khách hàng
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Họ tên -->
          <validation-provider
            #default="validationContext"
            name="Họ tên"
          >
            <b-form-group
              label="Họ tên"
              label-for="ho-ten"
            >
              <b-form-input
                id="full-name"
                v-model="userData.hoten"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Họ và tên đầy đủ"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Điện thoại -->
          <validation-provider
            #default="validationContext"
            name="Điện thoại"
          >
            <b-form-group
              label="Điện thoại"
              label-for="dien-thoai"
            >
              <b-form-input
                id="dien-thoai"
                v-model="userData.dien_thoai"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="0988xxxxxx"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Ngày sinh -->
          <validation-provider
            #default="validationContext"
            name="Ngày sinh"
          >
            <b-form-group
              label="Ngày sinh"
              label-for="ngay-sinh"
            >
              <flat-pickr
                id="ngay-sinh"
                v-model="userData.ngay_sinh"
                class="form-control"
                :config="configDate"
                placeholder="Chọn ngày sinh"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Họ tên phụ huynh -->
          <validation-provider
            #default="validationContext"
            name="Họ tên phụ huynh"
          >
            <b-form-group
              label="Họ tên phụ huynh"
              label-for="ho-ten-phu-huynh"
            >
              <b-form-input
                id="ho-ten-phu-huynh"
                v-model="userData.hoten_phu_huynh"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Họ và tên phụ huynh"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Ngày sinh phụ huynh -->
          <validation-provider
            #default="validationContext"
            name="Ngày sinh phụ huynh"
          >
            <b-form-group
              label="Ngày sinh phụ huynh"
              label-for="ngay-sinh-phu-huynh"
            >
              <flat-pickr
                id="ngay-sinh-phu-huynh"
                v-model="userData.ngay_sinh_phu_huynh"
                class="form-control"
                :config="configDate"
                placeholder="Chọn ngày sinh phụ huynh"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Đại lý -->
          <validation-provider
            #default="validationContext"
            name="Đại lý"
          >
            <b-form-group
              label="Đại lý"
              label-for="dai-ly-selection"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.dai_ly_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="daiLyOptions"
                :clearable="true"
                input-id="dai-ly-selection"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tỉnh thành -->
          <validation-provider
            #default="validationContext"
            name="Tỉnh thành"
          >
            <b-form-group
              label="Tỉnh thành"
              label-for="khu-vuc-selection"
            >
              <v-select
                v-model="userData.khu_vuc_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="khuVucOptions"
                :clearable="true"
                input-id="khu-vuc-selection"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Lưu lại
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Hủy
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import { Vi } from 'flatpickr/dist/l10n/vn'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      khuVucOptions: [],
      daiLyOptions: [],
      configDate: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Vi, // locale for this instance only
      },
    }
  },
  mounted() {
    const currentUser = getUserData()
    store
      .dispatch('app-user/fetchAllKhuVuc', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
      .then(response => {
        const { khuVuc } = response.data
        this.khuVucOptions = khuVuc
      })
      .catch(() => {
      })
    store
      .dispatch('app-user/fetchAllDaiLy', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
      .then(response => {
        this.daiLyOptions = response.data
      })
      .catch(() => {
      })

    this.$root.$on('updateKhach', id => {
      store.state.showBlock = true

      store
        .dispatch('app-user/getKhachHang', {
          auth: currentUser.auth_key,
          uid: currentUser.id,
          khach_hang: id,
        })
        .then(response => {
          store.state.showBlock = false
          this.userData = response.data
          if (response.data.phu_huynh_id !== '') {
            this.userData.hoten_phu_huynh = response.data.phu_huynh_id.hoten
            this.userData.ngay_sinh_phu_huynh = response.data.phu_huynh_id.ngay_sinh
          }
        })
        .catch(e => {
          store.state.showBlock = false
          this.showToast('danger', 'Thông báo', 'BellIcon', e.message)
        })
    })
  },
  created() {

  },
  setup(props, { emit }) {
    const currentUser = getUserData()
    const toast = useToast()

    const blankUserData = {
      hoten: '',
      dien_thoai: '',
      ngay_sinh: '',
      email: '',
      khu_vuc_id: '',
      dai_ly_id: '',
      dai_ly: 0,
      khach_hang: 1,
      hoten_phu_huynh: '',
      ngay_sinh_phu_huynh: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const setUserData = user => {
      userData.value = user
    }
    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }

    const onSubmit = () => {
      store.state.showBlock = true
      Object.assign(userData.value, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        khach_hang: 1,
        dai_ly: 0,
      })
      store.dispatch('app-user/addKhachHang', userData.value)
        .then(response => {
          showToast('success', 'Thông báo', 'BellIcon', response.data.content)
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          store.state.showBlock = false
        })
        .catch(e => {
          if (typeof e.response !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          } else if (typeof e.message !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.message)
          } else {
            showToast('danger', 'Thông báo', 'BellIcon', e)
          }
          store.state.showBlock = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      setUserData,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
