<template>
  <b-sidebar
    id="thanh-toan-them"
    :visible="isThanhToanThemSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-thanh-toan-them-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Thanh toán
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver2"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <dl
            v-if="giaoDichData.khach_hang !== null"
            class="row"
          >
            <dt class="col-sm-5">
              KHÁCH HÀNG
            </dt>
            <dd class="col-sm-7">
              <div class="float-sm-right">
                {{ giaoDichData.khach_hang.hoten }}
              </div>
            </dd>
            <dt class="col-sm-5">
              ĐIỆN THOẠI
            </dt>
            <dd class="col-sm-7">
              <div class="float-sm-right">
                {{ giaoDichData.khach_hang.dien_thoai }}
              </div>
            </dd>
            <dt class="col-sm-5">
              PHÍ XEM BÀI
            </dt>
            <dd class="col-sm-7">
              <div class="float-sm-right">
                {{ giaoDichData.khach_hang.so_tien != null ? Number( giaoDichData.khach_hang.so_tien).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }} <span class="text-muted"> VNĐ</span>
              </div>
            </dd>
            <dt class="col-sm-5">
              KHUYẾN MẠI
            </dt>
            <dd class="col-sm-7">
              <div class="float-sm-right">
                {{ giaoDichData.khuyen_mai != null ? Number( (giaoDichData.khuyen_mai).replaceAll(',','')).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }} <span class="text-muted"> VNĐ</span>
              </div>
            </dd>
            <dt class="col-sm-5">
              TỔNG
            </dt>
            <dd class="col-sm-7">
              <div class="float-sm-right">
                <h4 class="text-danger">
                  {{ giaoDichData.so_tien_can_thanh_toan != null ? Number( giaoDichData.so_tien_can_thanh_toan).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }} <span class="text-muted"> VNĐ</span>
                </h4>
              </div>
            </dd>
            <hr class="invoice-spacing">
            <dt class="col-sm-5">
              Đã thanh toán
            </dt>
            <dd class="col-sm-7">
              <div class="float-sm-right">
                {{ giaoDichData.khach_hang.so_tien_da_thanh_toan != null ? Number( giaoDichData.khach_hang.so_tien_da_thanh_toan).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }} <span class="text-muted"> VNĐ</span>
              </div>
            </dd>
            <dt class="col-sm-5">
              Còn lại
            </dt>
            <dd class="col-sm-7">
              <div class="float-sm-right">
                <h4 class="text-warning">
                  {{ Number( giaoDichData.so_tien_can_thanh_toan - giaoDichData.khach_hang.so_tien_da_thanh_toan).toLocaleString('vi', {minimumFractionDigits: 0}) }} <span class="text-muted"> VNĐ</span>
                </h4>
              </div>
            </dd>
          </dl>

          <!-- Khuyến mại -->
          <validation-provider
            #default="validationContext"
            name="Khuyến mại"
          >
            <b-form-group
              label="Khuyến mại"
              label-for="chiet-khau"
            >
              <cleave
                id="chiet-khau"
                v-model="giaoDichData.khuyen_mai"
                class="form-control"
                :raw="false"
                :state="getValidationState(validationContext)"
                :options="options.number"
                placeholder="10.000"
                @input="tinhThanhTien"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Số tiền -->
          <validation-provider
            #default="validationContext"
            name="Số tiền thanh toán"
          >
            <b-form-group
              label="Số tiền thanh toán"
              label-for="so-tien-thanh-toan"
            >
              <cleave
                id="so-tien-thanh-toan"
                v-model="giaoDichData.so_tien_giao_dich"
                class="form-control"
                :raw="false"
                :state="getValidationState(validationContext)"
                :options="options.number"
                placeholder="10,000"
                @input="tinhThanhTien"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <h4>CÔNG NỢ:
            <span class="float-sm-right">{{ giaoDichData.khach_hang !== null ? Number( giaoDichData.khach_hang.con_no).toLocaleString('vi', {minimumFractionDigits: 0}) : 0 }} <span class="text-muted"> VNĐ</span></span></h4>

          <!-- Ngày thanh toán -->
          <validation-provider
            #default="validationContext"
            name="Ngày thanh toán"
          >
            <b-form-group
              label="Ngày thanh toán"
              label-for="ngay-thanh-toan"
            >
              <flat-pickr
                id="ngay-thanh-toan"
                v-model="giaoDichData.ngay_thanh_toan"
                class="form-control"
                :config="configDate"
                placeholder="Chọn ngày thanh toán"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <p>Nhập mã giao dịch xác minh giao dịch</p>
          <b-row>
            <b-col
              cols="9"
              md="6"
            >
              <!--              ma_giao_dich-->
              <validation-provider
                #default="validationContext"
                name="Mã giao dịch"
                rules="required"
              >
                <b-form-group
                  label-for="ma-giao-dich"
                >
                  <b-form-input
                    id="ma-giao-dich"
                    v-model="giaoDichData.ma_giao_dich"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Điền mã giao dịch"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-success"
                @click="nhanMaGiaoDich()"
              >
                <feather-icon icon="CodeIcon" />
                Mã giao dịch
              </b-button>
            </b-col>
          </b-row>
          <hr class="invoice-spacing">

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon
                icon="DollarSignIcon"
                class="mr-50"
              />
              Thanh toán
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Hủy
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import router from '@/router'

export default {
  components: {
    flatPickr,
    Cleave,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isThanhToanThemSidebarActive',
    event: 'update:is-thanh-toan-them-sidebar-active',
  },
  props: {
    isThanhToanThemSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      configDate: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
    }
  },
  setup(props, { emit }) {
    const currentUser = getUserData()
    const toast = useToast()

    const blankGiaoDichData = {
      so_tien_giao_dich: 0,
      ngay_thanh_toan: '',
      active: 1,
      khach_hang: null,
      don_gia: 0,
      so_tien_can_thanh_toan: 0,
      so_tien_da_thanh_toan: 0,
      con_no: 0,
      khuyen_mai: 0,
      so_tien: 0,
      so_tien_thanh_toan_them: 0,
      auth: currentUser.auth_key,
      uid: currentUser.id,
      loai_giao_dich: 'Thanh toán đơn hàng',
      phan_loai: 'Khách hàng thanh toán',
      ma_giao_dich: null,
    }
    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }
    const giaoDichData = ref(JSON.parse(JSON.stringify(blankGiaoDichData)))
    const tinhThanhTien = () => {
      let daThanhToan = 0
      if ((giaoDichData.value.khach_hang !== null)) daThanhToan = (giaoDichData.value.khach_hang.so_tien_da_thanh_toan !== null ? Number(giaoDichData.value.khach_hang.so_tien_da_thanh_toan.replaceAll('.', '')) : 0)
      let donGia = 0
      if ((giaoDichData.value.so_tien !== 0)) donGia = Number(giaoDichData.value.so_tien.replaceAll(',', ''))
      let khuyenMai = 0
      if ((giaoDichData.value.khuyen_mai) !== 0) khuyenMai = Number(giaoDichData.value.khuyen_mai.replaceAll(',', ''))
      giaoDichData.value.so_tien_can_thanh_toan = donGia - khuyenMai
      if (giaoDichData.value.khach_hang !== null) giaoDichData.value.khach_hang.con_no = donGia - khuyenMai - daThanhToan - (giaoDichData.value.so_tien_giao_dich !== 0 ? Number((giaoDichData.value.so_tien_giao_dich).replaceAll(',', '')) : 0)
    }
    const setGiaoDichData = user => {
      giaoDichData.value = user
    }
    const nhanMaGiaoDich = () => {
      if (giaoDichData.value.so_tien_giao_dich === '0' || giaoDichData.value.so_tien_giao_dich === '' || giaoDichData.value.so_tien_giao_dich === null) showToast('danger', 'Thông báo', 'BellIcon', 'Chưa nhập số tiền')
      else if (giaoDichData.value.ngay_thanh_toan === '') showToast('danger', 'Thông báo', 'BellIcon', 'Chưa nhập ngày thanh toán')
      else {
        store.state.showBlock = true
        Object.assign(giaoDichData.value, {
          auth: currentUser.auth_key,
          uid: currentUser.id,
        })
        store.dispatch('app-user/nhanMaGiaoDich', giaoDichData.value)
          .then(response => {
            const oldGiaoDichData = giaoDichData.value
            Object.assign(oldGiaoDichData, {
              giao_dich: response.data.sendmagiaodich,
            })
            setGiaoDichData(oldGiaoDichData)
            showToast('success', 'Thông báo', 'BellIcon', response.data.content)
            store.state.showBlock = false
          })
          .catch(e => {
            if (typeof e.response !== 'undefined') {
              showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
            } else if (typeof e.message !== 'undefined') {
              showToast('danger', 'Thông báo', 'BellIcon', e.message)
            } else {
              showToast('danger', 'Thông báo', 'BellIcon', e)
            }
            store.state.showBlock = false
          })
      }
    }

    const resetuserData = () => {
      giaoDichData.value = JSON.parse(JSON.stringify(blankGiaoDichData))
    }
    const onSubmit = () => {
      store.state.showBlock = true
      Object.assign(giaoDichData.value, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
      store.dispatch('app-user/thanhToan', giaoDichData.value)
        .then(response => {
          emit('refetch-data')
          emit('update:is-thanh-toan-them-sidebar-active', false)
          store.state.showBlock = false
          const userData = JSON.parse(localStorage.getItem('userData'))
          Object.assign(userData, {
            doanh_so: response.data.doanh_so,
            tien_dau_tu: response.data.tien_dau_tu,
            so_bai: response.data.so_bai,
          })
          localStorage.setItem('userData', JSON.stringify(userData))

          router.replace(`/xem-bai/${giaoDichData.value.khach_hang.id}`)
            .then(() => {
              showToast('success', 'Thông báo', 'BellIcon', response.data.content)
            })
            .catch(() => {
            })
        })
        .catch(e => {
          if (typeof e.response !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          } else if (typeof e.message !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.message)
          } else {
            showToast('danger', 'Thông báo', 'BellIcon', e)
          }
          store.state.showBlock = false
        })
    }

    const {
      refFormObserver2,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      nhanMaGiaoDich,
      currentUser,
      giaoDichData,
      onSubmit,
      tinhThanhTien,
      refFormObserver2,
      getValidationState,
      resetForm,
      setGiaoDichData,
    }
  },
  mounted() {
    this.$root.$on('thanhToanThem', khachHang => {
      store.state.showBlock = true

      store
        .dispatch('app-user/loadThongTinThanhToan', {
          auth: this.currentUser.auth_key,
          uid: this.currentUser.id,
          khach_hang: khachHang,
        })
        .then(response => {
          this.giaoDichData.khach_hang = response.data
          this.giaoDichData.khuyen_mai = response.data.khuyen_mai
          this.giaoDichData.so_tien_can_thanh_toan = this.giaoDichData.khach_hang.so_tien - this.giaoDichData.khach_hang.khuyen_mai
          this.giaoDichData.so_tien = this.giaoDichData.khach_hang.so_tien
          store.state.showBlock = false
        })
        .catch(e => {
          store.state.showBlock = false
          this.showToast('danger', 'Thông báo', 'BellIcon', e.message)
        })
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
